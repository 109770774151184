<template>
  <div :class="['tag-pill', {'closeable': isCloseable}]">
    <div class="body" :style="{'color': this.textColor, 'background-color': this.backgroundColor}">
      <span><slot>{{text}}</slot></span>
      <b-icon class="close" v-if="isCloseable" icon="x-circle-fill" @click="closeClicked"></b-icon>
    </div>
  </div>
</template>

<script>
import { BBadge, BIcon } from 'bootstrap-vue'

export default {
  name: "TagPillComponent",
  components: {
  },
  props: {
    text: {
      type: [String, Number]
    },
    textColor: {
      type: String,
      Required: true      
    },
    backgroundColor: {
      type: String,
      Required: true      
    },
    isCloseable: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  computed: {},
  methods: {
    closeClicked () {
      this.$emit('close-clicked');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tag-pill {
  .body {
    border-radius: 3px;
    text-align: center;
  }
  .close {
    height: 1rem;
    width: 1rem;
    margin-left: 10px;
    color: white;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
