import _ from 'underscore';

export const GAMEYE_BLUE = "#007AFF";

export const AUTH_LEVELS = {
  ADDER: 500,
  MOD: 1000,
  ADMIN: 10000,
  OWNER: 100000
};

export const NAME_LENGTHS = {
  MIN_DISPLAY_NAME: 2,
  MAX_DISPLAY_NAME: 50,
  MIN_USER_NAME: 4,
  MAX_USER_NAME: 17
}

export const IMAGE_TYPES_BASE_URL_META_KEYS = {
  URL: "base_url",
  IMAGE: "base_image_url",
  PLATFORM: "base_platform_image_url",
  FLAG: "base_flag_image_url",
  RATINGS: "base_parental_ratings_image_url",
  PROFILE_PIC: "base_profile_image_url",
  DOCUMENTS: "base_documentation_url"
};

export const VGPC_GENRE_MAPPINGS = {
  fps: ["First-person shooter"],
  rpg: ["RPG"],
  "action & adventure": ["Action", "Adventure"],
  "action et adventure": ["Action", "Adventure"],
  "board & card": ["Board"],
  "soccer": ["Sports"],
  "basketball": ["Sports"],
  "third person shooter": ["Third-person shooter"],
  "survival horror": ["Horror"],
  "hockey": ["Sports"],
  "golf": ["Sports"],
  "football": ["Sports"],
  "sport": ["Sports"],
  "wrestling": ["Sports"],
  "fishing": ["Sports"],
  "baseball": ["Sports"],
  "horreur": ["Horror"],
  "extreme sports": ["Sports"],
  "skateboarding": ["Sports"],
  "shoot 'em up": ["Shoot 'em up"],
  "shoot'em up": ["Shoot 'em up"],
  "beat'em up": ["Beat 'em up"],
  "hack and slash": ["Hack 'n Slash"],
  "light gun" : ["Light Gun"],
  "visual novel" : ["Visual Novel"],
  "educational" : ["Education"],
  "dance" : ["Music"]
}

export const CATEGORIES = [
  {
    name: 'all',
    label: 'All',
    value: undefined,
    icon: 'logo-inverted'
  },
  {
    name: 'games',
    label: 'Games',
    value: 0,
    icon: 'game',
    groupName: 'Gaming Platforms'
  },
  {
    name: 'systems',
    label: 'Systems',
    value: 1,
    icon: 'system',
    groupName: 'Gaming Platforms'
  },
  {
    name: 'peripherals',
    label: 'Peripherals',
    value: '2,5',
    icon: 'peripheral',
    groupName: 'Gaming Platforms',
    children: [
      {
        name: 'controllers',
        label: 'Controllers',
        value: 2,
        icon: 'peripheral',
        groupName: 'Gaming Platforms'
      },
      {
        name: 'accessories',
        label: 'Accessories',
        value: 5,
        icon: 'peripheral',
        groupName: 'Gaming Platforms'
      }
    ]
  },
  {
    name: 'toys',
    label: 'Toys to Life',
    value: 3,
    icon: 'toy',
    groupName: 'Toys To Life'
  },
  {
    name: 'print',
    label: 'Print Media',
    value: 4,
    icon: 'print',
    groupName: 'Print Media'
  }
];

export const COLLECTION_TYPES = [
  {
    name: 'Library',
    label: 'Library',
    value: 0,
    icon: 'nav_library'
  },
  {
    name: 'Trade',
    label: 'Trade/Sell',
    value: 1,
    icon: 'nav_sell'
  },
  {
    name: 'Wishlist',
    label: 'Wishlist',
    value: 2,
    icon: 'nav_wishlist'
  },
]

export const QUEUE_TYPE_IDS = {
  TGDB: {
    EDIT: 0,
    NEW: 1,
    IMAGE: 2,
    DELETE: 4,
  },
  GAMEYE: {
    EDIT: 0,
    NEW: 1
  }
}

const itemFields = {
  common: [
    {
      label: "Name/Title",
      key: "title",
      required: true,
      help: "For English Language entries -- for the most part, exact title on box. Except we are now preferring that you drop things like 'Disney/Pixar' from the main title and put that as an alt title.</br>" +
        "For Japanese Language entries -- If it has an English title on the box, I’ve been preferring that.</br>" +
        "Otherwise, I prefer the method where it uses the special characters ō. There is another method where it turns characters like ō into ou. This could potentially be an alt title.</br>" +
        "NOTE: this can be discussed in the discord database chat!"
    },
    {
      label: "Alt Titles",
      key: "alt_titles",
      type: "multi-text",
      help: "Alternate titles along with Japanese Hiragana/Katakana/Kanji only titles"
    },
    {
      label: "Platform",
      key: "platform_id",
      type: "select",
      noSort: true,
      func: pId => { return this.parseListForMatchingId(this.platformsList, pId) },
      options: (src) => { return src.platformsList },
      customComponent: "platform",
      getProps: pId => {return { platformId: pId }},
      required: true,
      isPlatforms: true
    },
    {
      label: "Overview",
      key: "overview",
      type: "text-area",
      required: true,
      help: "English only. We will eventually use something for translation, but since the majority of our users speak English, and English users will want to be able to read about foreign games, English only.</br></br>" + 
        "If you would like to include specific “back of the box” text in the native language, this must come after the English overview. Example:</br></br>" +
        "(English overview)</br></br>" +
        "Back of the box:</br></br>" +
        "(Native language)"
    },
    {
      label: "Region",
      key: "country_id",
      func: cId => { return this.getFieldFromIdList(cId, this.metadata.countries, "abbr"); },
      type: "select",
      idListField: "abbr",
      options: (src) => { return _.values(src.metadata.countries) },
      required: true,
      help: "Region-ing is a difficult subject we are currently tackling. But there are cases where things do not yet fit perfectly. For example, we do not have a have a “Europe” region currently (though we might add one eventually). We have mostly individual countries as well a few meta-regions such as Benelux. But for cases where a game/item/sku was sold in multiple countries we currently “collapse” that into a single region.</br></br>"+
        "As an example, if a game was sold in both Canada and the US with the same SKU, we collapse that to US. If a game was sold in UK and France, we collapse that to UK. This could change in the future without needing any work on a user’s end, but it currently helps us make sure we aren’t making duplicate entries for a single SKU.</br></br>"+
        "Another note, is that for these new games being made by independent-ish groups, printed without ratings and with English language/subtitle options, we are putting those under the “World” region.</br></br>" +
        "Similarly, for digital releases, unless that digital release was only released in one region, we also mark that as 'World'"
    },
    {
      label: "Release Date",
      key: "release_date",
      func: d => { return d*1000 },
      type: "date",
      help: "Mark it with the region specific release date if possible. If you only have a year, mark it as January 1st, (YEAR)"
    }
  ],
  games: [
    {
      label: "Release Type",
      key: "release_type",
      type: "select",
      func: rId => { return this.getFieldFromIdList(rId, this.metadata.release_types); },
      options: (src) => { return src.metadata.release_types; },
      required: true,
      help: "Official -- an official retail release</br>" +
	          "Not For Resale -- marked 'not for resale'</br>" +
            "HomeBrew -- made mostly from the ground up hobby release</br>" +
            "Cancelled -- was in development and never sold -- typically region marked as 'World'</br>" +
            "AfterLife -- commercial release outside of the platform's lifespan. Like a new Gameboy game from Limited Run</br>" +
            "Demo -- demo release, sometimes in magazines, etc...</br>" +
            "Unlicensed -- </br>" +
            "Digital -- typically region marked as 'World'</br>" +
            "Hack -- typically region marked as 'World'</br>" +
            "Pirate -- </br>" +
            "Prototype -- a copy of the game before it was released</br>" +
            "BuiltIn -- only released as a title built in to the console/system</br>" +
            "Utility -- like test carts and stuff</br>" +
            "Video -- like UMD video releases for PSP and video releases for GBA</br>" +
            "Competition -- like DKC competition carts</br>" + 
            "MultiMedia -- </br>" +
            "Press Kit --</br>" +
            "Unlockable -- a mini game inside of another game</br>" +
            "Bonus -- </br>" +
            "Download -- (code in a box)</br>"
    },
    {
      label: "Publisher",
      key: "pubs",
      func: pIds => { return this.getFieldFromIdList(pIds, this.publishers); },
      type: "multiselect",
      options: (src) => { return _.values(src.publishers) }
    },
    {
      label: "Genre",
      key: "genres",
      func: gIds => { return this.getFieldFromIdList(gIds, this.genres); },
      type: "multiselect",
      options: (src) => { return _.values(src.genres) },
      required: true
    },
    {
      label: "Developer",
      key: "devs",
      func: dIds => { return this.getFieldFromIdList(dIds, this.developers); },
      type: "multiselect",
      options: (src) => { return _.values(src.developers) }
    },
    {
      label: "Rating",
      key: "rating_id",
      dbKey: ["game_specific", "rating_id"],
      func: pId => { return this.getFieldFromIdList(pId, this.metadata.parental_ratings); },
      type: "select",
      options: (src) => { return src.metadata.parental_ratings },
      idListField: "name",
      getIconImg: (opt, src) => { return opt.file ? src.getImageURLByType(opt.file, 0, 'RATINGS') : undefined; },
      required: true,
      help: "Only mark with the exact rating. If the rating does not exist, leave it blank or contact a moderator to get the missing ratings added. If it has no rating, mark 'not applicable'"
    },
    {
      label: "Players",
      key: "num_players",
      dbKey: ["game_specific", "num_players"],
      type: "number"
    },
    {
      label: "CoOp",
      key: "co_op",
      dbKey: ["game_specific", "co_op"],
      type: "boolean"
    }
  ],
  systems: [],
  controllers: [],
  accessories: [],
  toys: [],
  print: []
}

export const ITEM_FIELDS = _.mapObject(
  // select fields not 'common'
  _.omit(itemFields, 'common'),
  (fields) => {
    return [
      ...itemFields.common,
      ...fields
    ];
  }
);

export const ITEM_FIELDS_MAP = _.mapObject(
  ITEM_FIELDS,
  fields => {
    return _.indexBy(fields, 'key');
  }
);

export const ITEM_STATES = {
  GOOD: 1,
  DELETED: 2,
  NEEDS_REVIEW: 3
}

export const GE_TAG_COLORS = [
    "#F04754",
    "#FF9300",
    "#FFCC00",
    "#89CE08",
    "#17CEFF",
    "#0089FF",
    "#B96CFC",
    "#393939",
    "#818181",
    "#F5F5F5"
]

export const GE_TAG_TEXT_COLORS = [
  "#FFFFFF",
  "#FFFFFF",
  "#393939",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#393939"
]

export default {
  GAMEYE_BLUE,
  AUTH_LEVELS,
  NAME_LENGTHS,
  IMAGE_TYPES_BASE_URL_META_KEYS,
  VGPC_GENRE_MAPPINGS,
  QUEUE_TYPE_IDS,
  ITEM_FIELDS,
  ITEM_FIELDS_MAP,
  ITEM_STATES
};