<script>
import _ from "underscore";
import _l from "lodash";
import BrowsePageProps from "@/mixins/BrowsePageProps.js";

export default {
  mixins: [BrowsePageProps],
  props: {
    displayType: { type: String },
    filtersInUrl: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    sortSelectedObj () {
      return _.findWhere(this.sortOptions, { value: this.sortSelected });
    },
    totalItems () {
      return this.$store.state[this.store].itemsTotalCount;
    }
  },
  methods: {
    filtersChanged (newVal) {
      this.selectedFiltersObj = newVal;
      this.updatedFilters();
    },
    searchChanged () {
      this.updatedFilters();
    },
    sortChanged (val) {
      if (this.filtersInUrl) this.addToQueryParam('s', val);
      else this.getItems();
    },
    displayTypeChanged (val) {
      if (this.filtersInUrl) this.addToQueryParam('dt', val);
    },
    userEntriesCheckboxGroupChanged (val) {
      this.getItems() // do not push these to URL, they are per user and we aren't interested in preserving state right now
    },
    updatedFilters() {
      if (this.filtersInUrl) { // depend on URL to refresh items
        let allFilters = _.map(_.flatten(_.values(this.selectedFiltersObj)), (val) => { return val.groupId + '-' + val.id; });
        this.addToQueryParam('f', allFilters);
      } else {
        this.getItems();
      }
    },
    addToQueryParam (key, val) {
      let q = JSON.parse(JSON.stringify(this.$route.query));
      let updatedVal = _.isArray(val) && val.length === 1 ? val[0] : val;
      if (q[key] !== updatedVal) q[key] = val;
      if (!_.isEqual(q, this.$route.query)) this.$router.push({query: q});
    },
    getItemsByCategory (list, category) {
      let res = _.where(list, { name: category })
      return res
    },
    navToItem (id) {
      this.$router.push({ name: 'Details', params: { id: id } });
    },
    isSelectedItem (selected, id) {
      return selected === id;
    },
    buildFilterParam (paramObj) {
      if (this.query || this.search) paramObj.title = this.query || this.search;
      _.each(this.selectedFiltersObj, (gFilters, group) => {
        let func = this.searchGroupMeta[group].queryValFunc;
        let ids = func ? func(gFilters) : _.pluck(gFilters, 'id').join();
        paramObj[this.searchGroupMeta[group].queryKey] = ids;
      })
      paramObj.order = this.sortSelectedObj.dbVal;
      paramObj.asc = this.sortSelectedObj.asc;
      paramObj.cat = _.findWhere(this.categories, {name: this.category}).value;
      return paramObj;
    },
    buildPendingUserDataParam (paramObj) {
      if (this.selectedPendingUserEntriesFilters.length > 0) {
        paramObj.state = this.selectedPendingUserEntriesFilters.join(",");
        paramObj.creator_id = this.loggedInUser.id;
      }
      return paramObj;
    },
    buildUserCollectionTypeParams (paramObj) {
      if (this.collectionType !== undefined) {
        paramObj.collection_type = this.activeCollectionType.value;
      }
      return paramObj
    },
    pageReloadDueToItem(item) {
      let itemIdx = _.indexOf(this.itemsList, item);
      let itemPage = Math.floor(itemIdx / this.pageSize);
      this.$store.dispatch(`${this.store}/clearItemsAfter`, itemPage * this.pageSize);
      this.getItems();
    },
    getItems () {
      this.loading = true;
      let params = { "offset": (this.lPage - 1) * this.lPageSize, "limit": this.lPageSize };
      params = this.buildFilterParam(params);
      params = this.buildPendingUserDataParam(params);
      params = this.buildUserCollectionTypeParams(params);
      if (this.overrideParams) _l.merge(params, this.overrideParams);
      //Need to filter/sort/etc still
      this.queryForItems(params)
        .then(() => {
          this.loading = false;
        })
        .catch(e => {
          console.error('issue loading items', e);
        });
    },
    queryForItems (params) {
      return this.$store.dispatch(`${this.store}/getItems`, { params: params })
    }
  }
}
</script>