<template>
  <b-modal ref="loginPopover" id="loginPopover" title="Login" size="lg" body-class="no-padding" v-bind="$attrs"
    centered hide-footer hide-header :visible="value" @change="$emit('input', $event)" ok-title="Login">
    <div class="icon-container">
      <icon-set icon="logo" height="30px"></icon-set>
      <div class="label">GAMEYE</div>
    </div>
    <div class="content">
      <div :class="['panel side-info', { 'right': !existingUser}]">
        <transition name="fade" mode="out-in">
          <div v-if="existingUser" key="existing">
            <h3>Welcome Back!</h3>
            <p>Log in to manage your library!</p>
            <hr/>
            <p>If you don't already have an account,</p>
            <b-button @click="switchToCreate">Create An Account</b-button>
          </div>
          <div v-else key="new">
            <h3>Welcome!</h3>
            <p>
              You can now reserve your GAMEYE account! This is the first step toward an integrated GAMEYE website.
              <br><br>
              <span class="bold">Next Up:</span> User Data Submissions
            </p>
            <hr/>
            <p>If you already have an account,</p>
            <b-button @click="switchToSignIn">Sign In</b-button>
          </div>
        </transition>
      </div>
      <div class="panel signup-panel" :class="{ 'active': !existingUser }">
        <transition name="fade" mode="out-in">
          <div v-show="!existingUser">
            <b-form style="position: relative" @submit.prevent="createAccount">
              <div class="steps-container">
                <div id="step0" v-show="step === 0">
                  <h4 style="margin-bottom: 40px">Create GAMEYE Account</h4>
                  <div class="input-with-icon input-group" style="margin-bottom: 20px">
                    <b-form-input v-model="email" placeholder="Email" type="email" ref="email" :state="emailState.valid"></b-form-input>
                    <span>
                      <b-icon-envelope></b-icon-envelope>
                    </span>
                  </div>
                  <password-field v-model="password" style="margin-bottom: 20px" ref="password" />
                </div>
                <div id="step1" v-show="step === 1">
                  <h4 style="margin-bottom: 40px">Create a @username</h4>
                  <p class="left" style="font-size: 0.9em">A distinct, all lowercase, unique handle that will rarely change.</p>
                  <p class="grey left" style="font-size: 0.8em; margin-bottom: 40px">
                    This will be shown with your profile, profile cards, and any other social aspects on the site in the future.
                  </p>
                  <div class="input-with-icon input-group" style="margin-bottom: 5px">
                    <b-form-input id="new-username" v-model="username" placeholder="username" type="text" :state="usernameState.valid" @input="checkUsername" :formatter="(v) => { return v.toLowerCase(); }"></b-form-input>
                    <b-popover target="new-username" :show="usernameState.valid === false && usernamePopulated" triggers="">
                      {{usernameState.error}}
                    </b-popover>
                    <span>
                      <b-icon-person></b-icon-person>
                    </span>
                  </div>
                  <p class="grey left" style="font-size: 0.7em; margin-bottom: 30px">Valid characters: <span style="font-size: 1.1em">a-z, 1-9, _</span></p>
                </div>
                <div id="step3" v-show="step === 2">
                  <h4 style="margin-bottom: 40px">User Policies</h4>
                  <user-policies v-model="acceptedAgreements" />
                </div>
              </div>
              <div class="flex-col center">
                <b-button v-if="step < 2" variant="primary" @click="step += 1" :disabled="!stepButtonEnabled()">Next</b-button>
                <b-button v-else key="submit" type="submit" variant="primary" :disabled="!stepButtonEnabled()">Create Account</b-button>
                <b-button v-if="step > 0" variant="link" @click="step -= 1">&lt; Back</b-button>
              </div>
              <div id="stepIndicators" class="flex center">
                <div class="step" :class="{active: step === 0, complete: step > 0}"></div>
                <div class="step" :class="{active: step === 1, complete: step > 1}"></div>
                <div class="step" :class="{active: step === 2, complete: step > 2}"></div>
              </div>
            </b-form>
            <div v-show="networkError" class="error">
              <b-icon-exclamation-triangle-fill variant="danger"></b-icon-exclamation-triangle-fill>
              &nbsp;&nbsp;{{networkError}}
            </div>
          </div>
        </transition>
      </div>
      <div class="panel signin-panel"  :class="{ 'active': existingUser }">
        <transition name="fade" mode="out-in">
          <div v-show="existingUser">
            <h3 style="margin-bottom: 20px">Sign In to GAMEYE</h3>
            <login-form @login="loggedIn" ref="loginForm"></login-form>
          </div>
        </transition>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BForm, BFormInput, BButton, BIconEnvelope, BIconPerson, BPopover, BIconExclamationTriangleFill, BFormCheckbox } from 'bootstrap-vue';
import IconSet from '@/components/IconSet';
import LoginForm from '@/components/LoginForm';
import PasswordField from '@/components/PasswordField';
import UserPolicies from '@/components/UserPolicies';

export default {
  name: 'LoginModal',
  components: {
    BModal,
    BForm,
    BFormInput,
    BButton,
    BIconEnvelope,
    BIconPerson,
    BIconExclamationTriangleFill,
    BPopover,
    IconSet,
    LoginForm,
    PasswordField,
    UserPolicies
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    signup: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      existingUser: true,
      username: undefined,
      usernameState: {
        valid: null,
        error: null
      },
      email: undefined,
      emailState: {
        valid: null
      },
      password: undefined,
      usernameMaxCharacters: 17,
      usernameMinCharacters: 4,
      networkError: undefined,
      step: 0,
      acceptedAgreements: false
    }
  },
  computed: {
    usernamePopulated () {
      return this.username !== null && this.username !== undefined && this.username !== ''
    }
  },
  created () {
    this.existingUser = !this.signup;
  },
  watch: {
    email () {
      this.emailState.valid = this.$refs.email.checkValidity();
    }
  },
  methods: {
    passwordValid () {
      return this.$refs.password && this.$refs.password.passwordState;
    },
    emailValid () {
      return this.$refs.email && this.$refs.email.checkValidity();
    },
    createFormValid () {
      // had to put in method because wasn't being reactive in computed
      return this.usernameState && this.passwordValid() && this.emailValid();
    },
    switchToCreate () {
      this.existingUser = false;
    },
    switchToSignIn () {
      this.existingUser = true;
    },
    checkUsername () {
      if (this.usernamePopulated) {
        if (/^[\w]*$/.test(this.username) && this.username.length <= this.usernameMaxCharacters && this.username.length >= this.usernameMinCharacters) {
          this.usernameState.valid = true;
          this.usernameState.error = null;
        } else if (this.username.length > this.usernameMaxCharacters) {
          this.usernameState.valid = false;
          this.usernameState.error = `Username can be a maximum of ${this.usernameMaxCharacters} characters`
        } else if (this.username.length < this.usernameMinCharacters) {
          this.usernameState.valid = false;
          this.usernameState.error = `Username can be a minimum of ${this.usernameMinCharacters} characters`
        } else {
          this.usernameState.valid = false;
          this.usernameState.error = 'Username can only contain letters, numbers and underscores'
        }
      } else {
        this.usernameState.valid = this.usernameState.error = null;
      }
    },
    createAccount () {
      console.log('Creating account!');
      this.networkError = undefined;
      if (this.usernameState.valid) this.$store.dispatch('signUpAction', { email: this.email, pw: this.password, handle: this.username, accepts_policies: this.acceptedAgreements })
      .then(() => {
        this.$store.dispatch('signInAction', { email: this.email, password: this.password }).then(() => {
          this.$store.dispatch('getUserInfo').then(() => {
            this.$emit('created');
            this.$refs.loginPopover.hide();
          })
          .catch(err => {
            this.$store.dispatch('signOutAction').then(() => {
              console.error('issue signing into created account');
              this.handleError(err);
            })
          });
        });
      })
      .catch(err => {
        console.error('issue creating account', err);
        this.handleError(err);
      });
    },
    handleError(err) {
      if (err.response && err.response.status === 409) {
        this.usernameState.error = 'Could not create account. Account with this username or email address already exists.';
        this.usernameState.valid = false;
        this.step = 1;
      } else if (err.response.data) {
        this.networkError = 'Error: ' + err.response.data;
      } else {
        this.networkError = 'There was an error creating this account.'
      }
    },
    loggedIn () {
      this.$emit('loggedIn');
      this.$refs.loginPopover.hide();
    },
    stepButtonEnabled () {
      if (this.step === 0) {
        return this.emailValid() && this.passwordValid();
      } else if (this.step === 1) {
        return this.usernameState.valid;
      } else if (this.step === 2) {
        return this.acceptedAgreements;
      }
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin-bottom: 8px;
  line-height: 1.5em;
}

.panel {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.panel > div:first-child {
  margin: auto;
  padding: 32px;
}

.signup-panel > div:first-child {
  padding: 32px 15%;
}

.signin-panel h3,
.signup-panel h3 {
  margin-bottom: 40px;
}

.content {
  min-height: 500px;
  display: flex;
}

.side-info {
  width: 45%;
  min-height: 500px;
  background-color: #0D91FF;
  border-radius: 5px 0 0 5px;
  position: absolute;
  z-index: 10;
  right: 55%;
}

.side-info.right {
  right: 0;
  border-radius: 0 5px 5px 0;
}

.side-info {
  transition: right 0.5s ease, border-radius 0.5s ease;
  -webkit-transition: right 0.5s ease, border-radius 0.5s ease;
  -moz-transition: right 0.5s ease, border-radius 0.5s ease;
}

.signin-panel,
.signup-panel {
  width: 45%;
  position: relative;
}

.signin-panel,
.signup-panel {
  transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
}

.signin-panel.active,
.signup-panel.active {
  width: 55%;
}

.icon-container {
  position: absolute;
  padding: 10px;
  z-index: 15;
}

.icon-container > .label {
  padding-left: 10px;
  display: inline-block;
  margin-bottom: -5px;
}

.icon-container svg {
  margin-bottom: 5px;
}

.panel button.btn {
  margin: 5px 10px;
  width: 75%;
}

.panel button.btn.btn-link {
  margin: 0;
  padding: 0;
  width: auto;
  font-size: 0.8rem;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: opacity .2s;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.2s;
}

::v-deep .input-group input.is-valid {
  border-color: #28a745;
}

::v-deep .input-group input.is-valid + span {
  color: #28a745;
}

::v-deep .carousel-caption {
  position: static;
}

div.error {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  font-size: 0.8em;
  color: red;
}

#stepIndicators {
  gap: 8px;
  margin-top: 8px;
}

#stepIndicators .step {
  height: 3px;
  width: 32px;
  background-color: var(--background-color);
  &.active {
    background-color: var(--gameye-blue);
  }
}
</style>