<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "ItemsCollection",
  data () {
    return {
      transitionName: '',
      transitionMode: ''
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (from.name === "Library" && to.name === "Details") {
      this.transitionName = "slide-left";
    } else if (from.name === "Details" && to.name === "Library") {
      this.transitionName = "slide-right";
    } else {
      this.transitionName = "";
    }
    next();
  }
}
</script>

<style >
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  transition-delay: 0s;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: ease;
  transition-delay: 0s;
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(100%, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-100%, 0);
}

.title h3 {
  margin-bottom: 0;
}
</style>
