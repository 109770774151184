import Vue from "vue";
import Vuex from "vuex";
import axios from "./axios";
import { getAuth, signInWithEmailAndPassword, getIdTokenResult, signOut, confirmPasswordReset, sendEmailVerification, verifyPasswordResetCode } from "firebase/auth";
import { updateAuthToken, getUserAuth } from "@/mixins/utils.js";
import updates from "./data-updates.js";
import encyclopediaStore from "./encyclopediaStore";
import userCollectionStore from "./userCollectionStore";
import vgpcStore from "./vgpcStore";
import metadataStore from "./metadataStore";
import modQueueStore from "./modQueueStore";
import _ from 'underscore';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    updates: updates,
    encyclopedia: encyclopediaStore,
    userCollection: userCollectionStore,
    vgpc: vgpcStore,
    meta: metadataStore,
    queue: modQueueStore
  },
  state: () => ({
    filters: [],
    loadingUser: true,
    fbUser: null,
    user: null,
    userRoles: null,
    currency: 'USD'
  }),
  mutations: {
    setLoadingUser(state, isLoading) {
      state.loadingUser = isLoading;
    },
    setFbUser(state, user) {
      state.fbUser = user;
    },
    setUserInfo(state, info) {
      // deep copy to make sure to trigger UI update
      state.user = JSON.parse(JSON.stringify(info.user));
      if (state.user.user_blob) state.user.user_blob = JSON.parse(state.user.user_blob);
      state.userRoles = info.roles;
    },
    clearUserInfo(state) {
      state.user = null;
      state.userRoles = null;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setCurrency(state, val) {
      state.currency = val;
    }
  },
  actions: {
    authAction(context) {
      return getUserAuth().then(({user}) => {
        context.commit('setFbUser', user || null);
        // no user so not going to load user info from our endpoint
        if (!user) context.commit('setLoadingUser', false);
        else context.dispatch('getUserInfo').then(() => {
          context.commit('setLoadingUser', false);
        });

        return user;
      });
    },
    signUpAction(context, data) {
      return axios.post("/register_user", data)
        .then(response => {
          console.log('Success signing up!', response);
        })
        .catch(error => {
          console.error('Signup error!', error.code + ':' + error.message);
          throw error;
        })
    },
    signInAction(context, data) {
      const auth = getAuth();
      return signInWithEmailAndPassword(auth, data.email, data.password)
      .then((response) => {
        getIdTokenResult(response.user)
          .then(result => {
            context.commit('setFbUser', response.user);
            console.log('Success logging in!', response.user.email);
            updateAuthToken({token: result.token, expires: result.expirationTime})
          });
      })
      .catch(error => {
        console.error('Login error!', error.code + ':' + error.message);
        throw error;
      })
    },
    signOutAction(context) {
      signOut(getAuth())
      .then(() => {
        console.log('Successfully logged out!');
        context.commit('setFbUser', null);
        context.commit('clearUserInfo');
      })
      .catch(error => {
        console.error('Logout error!', error.code + ':' + error.message);
      });
    },
    sendPasswordReset({}, email) {
      return axios.post('/reset_password', {email: email});
    },
    resetPassword({}, {password, fbCode}) {
      const auth = getAuth();
      return confirmPasswordReset(auth, fbCode, password);
    },
    checkPasswordResetCode({}, code) {
      const auth = getAuth();
      return verifyPasswordResetCode(auth, code);
    },
    resendVerificationAction() {
      const auth = getAuth();
      return sendEmailVerification(auth.currentUser);
    },
    validateEmail(context, data) {
      return axios.post('/finish_sign_up', data);
    },
    getUserInfo(context) {
      return axios.get('/logged_in_user_info').then((response) => {
        context.commit('setUserInfo', response.data);
        return response.data;
      })
    },
    editUserInfo({commit}, data) {
      return axios.post('/edit_user', data).then((response) => {
        commit('setUserInfo', response.data);
      });
    },
    checkNameExists(context, name) {
      return axios.post("/user_name_unique", {handle: name}).then(response => {
        return response.data;
      });
    },
    searchForUsers(context, params) {
      return axios.post("/user_search", params).then(response => {
        return response.data;
      });
    },
    editUserRoles(context, data) {
      return axios.post("/update_user_roles", data).then(response => {
        return response.data;
      });
    },
    inviteUser(context, data) {
      return axios.post("/send_sign_up", data).then(response => {
        return response.data;
      });
    },
    getDataTriggerOptions() {
      return axios.get("/data_process_types").then(response => {
        return response.data;
      });
    },
    triggerDataUpdate(context, data) {
      return axios.post("/run_data_process", {data_process_type: data});
    },
    setCurrency(context, currency) {
      context.commit("setCurrency", currency);
      localStorage.setItem('currency', currency);
    },
    getRegionCounts() {
      return axios.get("/temp/unclassified_games_counts").then(response => {
        return response.data;
      });
    },
    getPriceCounts() {
      return axios.get("/temp/unmatched_prices_counts").then(response => {
        return response.data;
      });
    }
  },
  getters: {
    /*getItem: (state) => (id) => {
      //TODO: figure out how to combine this all for cached results
    }*/
    getUserRoles (state) {
      return state.user ? state.userRoles : undefined;
    },
    getUserLevel (state) {
      if (state.user && state.meta.metadata) {
        var authMap = _.object(_.pluck(state.meta.metadata.auth_levels, 'id'), state.meta.metadata.auth_levels);
        let roleLevels = _.map(state.userRoles, function (r) { return authMap[r.auth_id] ? authMap[r.auth_id].level : 0; })
        return !_.isEmpty(roleLevels) ? _.max(roleLevels) : 0;
      }
    }
  }
});



export default store;
