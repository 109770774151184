<template>
  <div id="base" :class="{'no-footer': noFooter}">
    <nav-top-bar v-if="!hideTopBar" :search-to="searchable ? undefined : 'Browse'" :hide-links="hideLinks" :hide-search="hideSearch">
      <template v-slot:afterSearch>
        <template v-if="loggedInUser?.verified">
          <b-button variant="outline-primary" size="sm" style="white-space: nowrap" @click="showNewItemModal = true">+ Add New Entry (Beta)</b-button>
        </template>
      </template>
    </nav-top-bar>
    <router-view id="content" v-if="$store.state.meta.allMetaLoaded && !$store.state.loadingUser" />
    <div v-else class="flex center v-center h-100"><b-spinner /></div>
    <nav-footer v-if="!noFooter" />
    <new-item-modal v-model="showNewItemModal"></new-item-modal>
  </div>
</template>

<script>
import NavTopBar from '@/components/NavTopBar.vue';
import {BSpinner, BButton} from "bootstrap-vue";
import NewItemModal from '@/components/NewItemModal';
import NavFooter from '@/components/NavFooter';

export default {
  metaInfo: {
    title: "GAMEYE"
  },
  components: {
    NavTopBar,
    BButton,
    BSpinner,
    NewItemModal,
    NavFooter
  },
  data () {
    return {
      showNewItemModal: false
    }
  },
  created() {
    this.$store.dispatch('authAction').then(() => {
      this.$store.dispatch('meta/getAllMeta');
    });
    if(localStorage.getItem('currency')) this.$store.dispatch('setCurrency', localStorage.getItem('currency'));
  },
  computed: {
    hideTopBar () {
      return this.$route.matched.some(r => r.meta.hideTopBar);
    },
    hideSearch () {
      return this.$route.matched.some(r => r.meta.hideSearch);
    },
    hideLinks () {
      return this.$route.matched.some(r => r.meta.hideLinks);
    },
    noFooter () {
      return this.$route.matched.some(r => r.meta.noFooter);
    },
    searchable () {
      return this.$route.meta.searchable;
    }
  }
}
</script>

<style lang="scss" scoped>
#content {
  padding: 15px;
}
#footer {
  left: 0;
  bottom: 0;
  z-index: 250;
  margin-top: 24px;
}
</style>